import { useState, useEffect, useCallback } from 'react';

import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';

import { usePathname } from 'next/navigation';

import { Box } from '@mui/material';
import Logo from '@/app/components/logo';
import SvgColor from '@/app/components/svg-color';
import Scrollbar from '@/app/components/scrollbar';

import NavList from './nav-list';
import { navConfig } from '@/app/utilities/consts';

// ----------------------------------------------------------------------

export default function NavMobile() {
  const pathname = usePathname();

  const [openMenu, setOpenMenu] = useState(false);

  const handleOpenMenu = useCallback(() => {
    setOpenMenu(true);
  }, []);

  const handleCloseMenu = useCallback(() => {
    setOpenMenu(false);
  }, []);

  useEffect(() => {
    if (openMenu) {
      handleCloseMenu();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  return (
    <Box
      sx={{
        display: {
          xs: 'block',
          md: 'none',
        },
      }}
    >
      <IconButton onClick={handleOpenMenu} sx={{ ml: 1 }}>
        <SvgColor src="/assets/icons/navbar/ic_menu_item.svg" />
      </IconButton>

      <Drawer
        open={openMenu}
        onClose={handleCloseMenu}
        sx={{ zIndex: 1400 }}
        PaperProps={{
          sx: {
            pb: 5,
            width: 260,
          },
        }}
      >
        <Scrollbar>
          <Logo sx={{ mx: 2.5, my: 3 }} />

          {navConfig.map(list => (
            <NavList key={list.title} data={list} />
          ))}
        </Scrollbar>
      </Drawer>
    </Box>
  );
}
