import { m, useAnimation, useInView } from 'framer-motion';
import { useEffect, useRef } from 'react';

const InViewAnimationComponent = ({ children }) => {
  const controls = useAnimation(); // Controls for triggering animation
  const ref = useRef(null); // Reference to the component
  const isInView = useInView(ref, { threshold: 0.1 }); // Detect when component is at least 10% visible

  useEffect(() => {
    if (isInView) {
      controls.start({
        opacity: 1,
        y: 0,
        transition: { duration: 0.5 },
      });
    } else {
      controls.start({
        opacity: 0,
        y: 100,
      });
    }
  }, [isInView, controls]);

  return (
    <m.div ref={ref} animate={controls} initial={{ opacity: 0, y: 100 }}>
      {children}
    </m.div>
  );
};

export default InViewAnimationComponent;
