'use client';

import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Unstable_Grid2';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

import { footerLinks } from '@/app/utilities/consts';
import Logo from '../logo';
import Iconify from '../iconify';

import { RouterLink } from '../router-link/router-link';
import { useAppSettingsContext } from '../contexts/app-settings-context';

export default function Footer() {
  const { settings } = useAppSettingsContext();

  const contactSettings = Array.isArray(settings)
    ? settings.find(item => item.key === 'contact-setting')
    : undefined;

  return (
    <Box
      component="footer"
      sx={{
        position: 'relative',
        bgcolor: 'background.default',
      }}
    >
      <Divider />

      <Container
        sx={{
          pt: 10,
          pb: 5,
          textAlign: { xs: 'center', md: 'unset' },
        }}
      >
        <Logo sx={{ mb: 3 }} />

        <Grid
          container
          justifyContent={{
            xs: 'center',
            md: 'space-between',
          }}
        >
          <Grid xs={8} md={3}>
            <Typography
              variant="body2"
              sx={{
                maxWidth: 270,
                mx: { xs: 'auto', md: 'unset' },
              }}
            >
              Streaming Tech LLC offers a seamless streaming experience with our IPTV player,
              designed for fast loading and a bug-free performance. Our beautiful user interface
              ensures an enjoyable viewing experience, and with multi-platform support, you can
              access your favorite channels on any device. Please note, we provide the IPTV player
              only and do not offer content. Enjoy! 🚀
            </Typography>

            <Stack
              direction="row"
              justifyContent={{ xs: 'center', md: 'flex-start' }}
              sx={{
                mt: 3,
                mb: { xs: 5, md: 0 },
              }}
            >
              {contactSettings?.value.map(social => (
                <IconButton
                  href={social.contact_link}
                  key={social.contact_link}
                  sx={{
                    '&:hover': {
                      bgcolor: social.hoverColor,
                    },
                  }}
                >
                  <Iconify icon={social.icon_name} />
                </IconButton>
              ))}
            </Stack>
          </Grid>

          <Grid xs={12} md={6}>
            <Stack spacing={5} direction={{ xs: 'column', md: 'row' }}>
              {footerLinks.map(list => (
                <Stack
                  key={list.headline}
                  spacing={2}
                  alignItems={{ xs: 'center', md: 'flex-start' }}
                  sx={{ width: 1 }}
                >
                  <Typography component="div" variant="overline">
                    {list.headline}
                  </Typography>

                  {list.children.map(link => (
                    <Link
                      key={link.name}
                      component={RouterLink}
                      href={link.href}
                      color="inherit"
                      variant="body2"
                    >
                      {link.name}
                    </Link>
                  ))}
                </Stack>
              ))}
            </Stack>
          </Grid>
        </Grid>

        <Typography variant="body2" sx={{ mt: 10 }}>
          © 2024. All rights reserved
        </Typography>
      </Container>
    </Box>
  );
}
