import { useState, useEffect, useCallback } from 'react';

import { useActiveLink, usePathname } from '@/app/components/hooks';

import { NavItem } from './nav-item';

export default function NavList({ data }) {
  const pathname = usePathname();

  const active = useActiveLink(data.path);

  const [openMenu, setOpenMenu] = useState(false);

  const handleOpenMenu = useCallback(() => {
    if (data.children) {
      setOpenMenu(true);
    }
  }, [data.children]);

  const handleCloseMenu = useCallback(() => {
    setOpenMenu(false);
  }, []);

  useEffect(() => {
    if (openMenu) {
      handleCloseMenu();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  return (
    <>
      <NavItem
        open={openMenu}
        onMouseEnter={handleOpenMenu}
        onMouseLeave={handleCloseMenu}
        title={data.title}
        path={data.path}
        hasChild={!!data.children}
        externalLink={data.path.includes('http')}
        active={active}
      />
    </>
  );
}
