import { Stack, useTheme } from '@mui/material';
import { useAnimation, useScroll, m } from 'framer-motion';
import { useEffect } from 'react';
import Iconify from '../iconify';

export const BackToTop = () => {
  const { scrollYProgress } = useScroll();
  const controls = useAnimation();
  const theme = useTheme();

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  useEffect(() => {
    scrollYProgress.onChange(progress => {
      if (progress > 0.1) {
        controls.start({ opacity: 1, pointerEvents: 'auto' });
      } else {
        controls.start({ opacity: 0, pointerEvents: 'none' });
      }
    });
  }, [scrollYProgress, controls]);

  return (
    <m.div onClick={scrollToTop} initial={{ opacity: 0, pointerEvents: 'none' }} animate={controls}>
      <Stack
        zIndex={10}
        sx={{
          position: 'fixed',
          right: 24,
          bottom: 24,
          width: 50,
          height: 50,
          bgcolor: theme.palette.background.paper,
          borderRadius: '50%',
          justifyContent: 'center',
          alignItems: 'center',
          boxShadow: theme.customShadows.z8,
          cursor: 'pointer',
        }}
      >
        <Iconify icon="gravity-ui:arrow-up-to-line" width={24} />
      </Stack>
    </m.div>
  );
};
