import { useState, useCallback } from 'react';

import { useActiveLink } from '@/app/components/hooks';

import { NavItem } from './nav-item';

// ----------------------------------------------------------------------

export default function NavList({ data }) {
  const active = useActiveLink(data.path);

  const [openMenu, setOpenMenu] = useState(false);

  const handleToggleMenu = useCallback(() => {
    if (data.children) {
      setOpenMenu(prev => !prev);
    }
  }, [data.children]);

  return (
    <>
      <NavItem
        open={openMenu}
        onClick={handleToggleMenu}
        title={data.title}
        path={data.path}
        icon={data.icon}
        hasChild={!!data.children}
        externalLink={data.path.includes('http')}
        active={active}
      />
    </>
  );
}
