'use client';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import Toolbar from '@mui/material/Toolbar';
import { useTheme } from '@mui/material/styles';
import Container from '@mui/material/Container';
import { useOffSetTop } from '../hooks/use-off-set-top';
import { HEADER, paths } from '@/app/utilities/consts';
import { bgBlur } from '@/app/theme/css';
import Logo from '../logo';
import NavDesktop from '../navigation/top/desktop';
// import { SettingsButton } from '../settings/button';
import NavMobile from '../navigation/top/mobile';
import HeaderShadow from './header-shadow';

export const MainHeader = () => {
  const theme = useTheme();

  const offsetTop = useOffSetTop({}, HEADER.H_DESKTOP);

  return (
    <AppBar sx={{ zIndex: 1300 }}>
      <Toolbar
        disableGutters
        sx={{
          height: {
            xs: HEADER.H_MOBILE,
            md: HEADER.H_DESKTOP,
          },
          transition: theme.transitions.create(['height'], {
            easing: theme.transitions.easing.easeInOut,
            duration: theme.transitions.duration.shorter,
          }),
          ...(offsetTop && {
            ...bgBlur({
              color: theme.palette.background.default,
            }),
            height: {
              md: HEADER.H_DESKTOP_OFFSET,
              xs: HEADER.H_MOBILE,
            },
          }),
        }}
      >
        <Container sx={{ height: 1, display: 'flex', alignItems: 'center' }}>
          <Logo />

          <Box sx={{ flexGrow: 1 }} />

          <NavDesktop />

          <Stack alignItems="center" direction="row">
            <Button variant="outlined" href={paths.becomeReseller}>
              Become Reseller
            </Button>

            {/* <SettingsButton
              sx={{
                ml: { xs: 1, md: 0 },
                mr: { md: 2 },
              }}
            /> */}

            <NavMobile />
          </Stack>
        </Container>
      </Toolbar>

      {offsetTop && <HeaderShadow />}
    </AppBar>
  );
};
